<template>
  <div class="mt-2">
    <span v-if="showEditButton">
      <edit-2-icon
        @click="goToEdit()"
        class="float-right editIcon"
      ></edit-2-icon>
    </span>
    <vs-col class="pr-0" vs-xs="12">
      <vs-row>
        <vs-col vs-w="4" v-for="(contact, index) in payload" :key="index">
          <label class="vs-input--label pl-0 review-label w-full">{{contact.name}}</label>
          <p class="textBlack">{{contact.email}}</p>
          <p class="textBlack">{{contact.phoneNumber}}</p>
        </vs-col>
      </vs-row>
    </vs-col>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
export default {
  components: {
    Edit2Icon,
  },
  name: "ContactDetails",
  props: ["contactDetails", "userId", "showEditButton"],
  data() {
    return {
      payload: [],
    }
  },
  methods: {
    goToEdit() {
      this.$router.push({
        name: "onboarding-form",
        params: { introducerRequestedId: this.userId, card: "businessInfo" },
        query: {
          scrollTo: 'contactDetails'
        }
      });
    },
  },
  mounted(){
    this.payload = this.contactDetails;
  }
};
</script>
