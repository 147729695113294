<template>
  <div class="onboarding-form">
    <div class="mt-2">
      <span v-if="showEditButton">
        <edit-2-icon @click="goToEdit()" class="float-right"></edit-2-icon>
      </span>

      <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Trading name</label>
          <p class="textBlack mb-0 text-base">{{ payload.tradingName }}</p>
        </div>
      </div>
      <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Industry </label>
          <p class="textBlack mb-0 text-base">{{ payload.industry }}</p>
        </div>

        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Category </label>
          <p class="textBlack mb-0 text-base">{{ payload.category }}</p>
        </div>
      </div>
      <div class="flex mt-6 flex-wrap" v-if="payload.websiteUrl">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Website URL</label>
          <p class="textBlack mb-0 text-base">{{ payload.websiteUrl }}</p>
        </div>
      </div>
      <div class="flex mt-6 flex-wrap">
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Business email address</label>
          <p class="textBlack mb-0 text-base">{{ payload.businessEmailAddress }}</p>
        </div>
        <div class="input-grp">
          <label class="vs-input--label w-full review-label">Business phone number</label>
          <p class="textBlack mb-0 text-base">{{ payload.businessPhoneNumber }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
export default {
  components: {
    Edit2Icon,
  },
  name: "GeneralInformation",
  props: {
    generalInfo: { type: Object },
    userId: String,
    showEditButton: Boolean,
  },
  data() {
    return {
      payload: {
        tradingName: "",
        industry: "",
        category: "",
        websiteUrl: "",
        businessEmailAddress: "",
        businessPhoneNumber: "",
      },
    };
  },
  methods: {
    goToEdit() {
      this.$router.push({
        name: "onboarding-form",
        params: { introducerRequestedId: this.userId, card: "businessInfo" },
      });
    },
    getRegisteredAddress(data){
      
      let addressData = [];
      if(data.appartmentDetail){
        addressData.push(data.appartmentDetail);
      }
      if(data.streetAddress){
        addressData.push(data.streetAddress);
      }
      if(data.suburb){
        if(data.state){
          addressData.push(data.suburb+' '+data.state);
        }else{
          addressData.push(data.suburb);
        }
      }
      return addressData.join(", ");

    },
  },
  mounted() {
    this.payload = this.generalInfo;
  },
};
</script>
