<template>
  <div>
    <div v-for="(entityDetail, countent) in entityList" :key="countent">
      <vs-row>
        <div class="w-full mb-8">
          <div class="flex mb-4 items-center">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4V2C10 0.9 9.1 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V6C20 4.9 19.1 4 18 4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM17 16H10V14H12V12H10V10H12V8H10V6H17C17.55 6 18 6.45 18 7V15C18 15.55 17.55 16 17 16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z" fill="#BDBDBD"/>
            </svg>

            <h4 class="lg:text-xl md:text-lg text-sm mb-0 ml-5">
              Business entity {{ countent + 1 }}
            </h4>
          </div>
          <hr class="line-hr mb-8" />
          <div class="onboarding-form">
            <span v-if="showEditButton">
              <edit-2-icon @click="goToEdit(countent)" class="float-right"></edit-2-icon>
            </span>
            <div>
              <div class="flex items-center flex-wrap">
                <div class="input-grp">
                  <label class="vs-input--label w-full review-label">Entity type</label>
                  <p class="textBlack">{{ entityDetail.entityType }}</p>
                </div>
              </div>

              <div v-if="entityDetail.entityType === 'Company'">
                <div class="flex items-center flex-wrap">
                  <div class="input-grp mt-5">
                    <label class="vs-input--label w-full review-label">Company name</label>
                    <p class="textBlack">{{ entityDetail.companyName }}</p>
                  </div>
                </div>
              </div>

              <div>
                <div class="flex items-center flex-wrap">
                  <div class="input-grp mt-5" v-if="entityDetail.entityType === 'Company'">
                    <label class="vs-input--label w-full review-label">ACN</label>
                    <p class="textBlack">{{ entityDetail.acn }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.abn && entityDetail.entityType === 'Company'">
                    <label class="vs-input--label w-full review-label">
                      ABN (if registered under this company)
                    </label>
                    <p class="textBlack">{{ entityDetail.abn }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label w-full review-label">Full name</label>
                    <p class="textBlack">{{ entityDetail.fullName }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.abn && entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label w-full review-label">
                      ABN (if registered under this individual)
                    </label>
                    <p class="textBlack">{{ entityDetail.abn }}</p>
                  </div>
                </div>

                <div class="flex items-center flex-wrap">
                  <div class="input-grp mt-5" v-if="entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label w-full review-label">Mobile phone number</label>
                    <p class="textBlack">{{ entityDetail.mobileNumber }}</p>
                  </div>

                  <div class="input-grp mt-5" v-if="entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label w-full review-label">Email address</label>
                    <p class="textBlack">{{ entityDetail.emailAddress }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="entityDetail.entityType">
              <!--  registered address  -->
              <div class="flex flex-wrap">
                <div class="input-grp mt-5">
                  <label class="vs-input--label w-full review-label">Registered Address</label>
                  <p class="textBlack">
                    {{ getRegisteredAddress(entityDetail.registeredAddress) }}
                  </p>
                </div>

                <div class="input-grp mt-5">
                  <label class="vs-input--label w-full review-label">Trading Address</label>
                  <p class="textBlack">
                    {{ getRegisteredAddress(entityDetail.tradingAddress) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-4 flex flex-wrap" v-if="entityDetail.entityType != '' && entityDetail.boCustomFields.length > 0">
              <div v-for="(boFields, boIndexNumber) in entityDetail.boCustomFields" :key="boIndexNumber" class="input-grp">
                <div class="mb-5" v-if="matchEntityWithBoAndCustomCf(countent, boIndexNumber)">
                  <label class="vs-input--label w-full review-label">{{ boFields.label }}</label>

                  <p class="textBlack" v-if="boFields.dataType === 'currency'">$ {{ formatToCurrency(boFields.value) }}</p>
                  <p class="textBlack" v-else-if="boFields.dataType === 'number'">{{ formatToCurrency(boFields.value) }}</p>
                  <p class="textBlack" v-else-if="boFields.dataType === 'file'"><S3FileGenerator :document="boFields.value"/></p>
                  <p class="textBlack" v-else>{{ boFields.value }}</p>
                </div>
              </div>
            </div>

            <div v-if="entityDetail.entityType != ''">
              <div>
                <label class="vs-input--label w-full review-label">Is this entity acting on behalf of a Trust?</label>
                <p class="textBlack">
                  {{ entityDetail.isEntityActAsTrust === 'yes' ? "Yes" : "No" }}
                </p>

                <div v-if="entityDetail.isEntityActAsTrust == 'yes' || entityDetail.isEntityActAsTrust == true" class="flex items-center flex-wrap mt-6">
                  <div class="input-grp">
                    <label class="vs-input--label w-full review-label">Trust name</label>
                    <p class="textBlack">{{ entityDetail.trustName }}</p>
                  </div>

                  <div class="input-grp" v-if="entityDetail.trustAbn">
                    <label class="vs-input--label w-full review-label">ABN (if registered under this Trust)</label>
                    <p class="textBlack">{{ entityDetail.trustAbn }}</p>
                  </div>

                  <div class="input-grp mt-5 mb-8">
                    <label class="vs-input--label w-full review-label">Trust deed</label>
                    <S3FileGenerator
                      :document="
                        typeof entityDetail.trustDeedFiles === 'string'
                          ? entityDetail.trustDeedFiles
                          : entityDetail.trustDeedFiles !== undefined
                          ? entityDetail.trustDeedFiles.name
                          : null
                      "
                    />
                  </div>
                </div>

                <div v-if="entityDetail.entityType === 'Company'">
                  <h3 class="text-base font-normal mt-6">Beneficial owners</h3>
                  <hr class="line-hr mt-4 mb-6" />

                  <div class="mb-8" v-for="(bodetail, boindex) in entityDetail.beneficialOwners" :key="boindex">
                    <h3 class="text-base font-normal">
                      Beneficial owner {{ parseInt(boindex) + 1 }}
                    </h3>

                    <div class="input-grp mt-3">
                      <label class="vs-input--label w-full review-label">Full name</label>
                      <p class="textBlack">{{ bodetail.fullName }}</p>
                    </div>

                    <div class="flex items-center flex-wrap mt-3">
                      <div class="input-grp">
                        <label class="vs-input--label w-full review-label">Mobile phone number</label>
                        <p class="textBlack">{{ bodetail.mobileNumber }}</p>
                      </div>

                      <div class="input-grp">
                        <label class="vs-input--label w-full review-label">Email address</label>
                        <p class="textBlack">{{ bodetail.email }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-row>
    </div>
  </div>
</template>

<script>
import S3FileGenerator from "../../components/S3FileGenerator";
import { Edit2Icon } from "vue-feather-icons";

export default {
  name: "BusinessEntity",
  components: {
    Edit2Icon,
    S3FileGenerator,
  },
  props: {
    entityList: { type: Array },
    userId: String,
    showEditButton: Boolean,
    businessOwnershipCustomFields: { type: Array },
  },
  data() {
    return {};
  },
  methods: {
    goToEdit(key) {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "businessOwnership",
        },
        query: {
          scrollTo:"entity-"+key
        }
      });
    },

    getRegisteredAddress(data) {
      let addressData = [];
      if (data.appartmentDetail) {
        addressData.push(data.appartmentDetail);
      }
      if (data.streetAddress) {
        addressData.push(data.streetAddress);
      }
      if (data.suburb) {
        if (data.state) {
          addressData.push(data.suburb + " " + data.state);
        } else {
          addressData.push(data.suburb);
        }
      }
      if (data.country) {
        addressData.push(data.country);
      } else {
        addressData.push("Australia");
      }
      return addressData.join(", ");
    },

    formatToCurrency(amount) {
      if (!amount) return amount;
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(amount).replace("$", "");
    },

    matchEntityWithBoAndCustomCf(boIndex, cfIndex) {
      const boEntityType = this.entityList[boIndex].entityType;
      const cfEntityType = this.businessOwnershipCustomFields[cfIndex].entity;
      if (cfEntityType.includes(boEntityType.toLowerCase())) {
        return true;
      }
      return false;
    },
  },
};
</script>

