<template>
  <div class="bank-account-review">
    <!-- {{bankAccount}} -->
    <template v-for="(bank, key) in bankAccount">
      <!-- <template> -->
      <vs-row :key="key" class="bank-margin">
      <vs-col  vs-w="12">
        <div>
          <div class="flex items-center mb-4">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 4V2C10 0.9 9.1 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V6C20 4.9 19.1 4 18 4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM17 16H10V14H12V12H10V10H12V8H10V6H17C17.55 6 18 6.45 18 7V15C18 15.55 17.55 16 17 16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z"
                fill="#BDBDBD"/>
            </svg>
            <h5 class="text-base font-normal ml-3">Bank account configuration {{ key + 1 }} </h5>
            <edit-2-icon
              v-if="showEditButton"
              @click="goToEdit(key)"
              class="ml-auto inline mb-0 md:hidden"
            ></edit-2-icon>
          </div>
          <hr class="line-hr"/>
        </div>
        <div class="onboarding-form banks">
          <div class="pt-6 md:pt-10 mt-1 mb-12">
            <div class="grid grid-cols-12 gap-y-6 md:gap-y-0 md:gap-x-6 lg:gap-x-10">
              <div class="col-span-1 md:col-span-1 lg:col-span-2 hidden md:block md:order-last">
                <edit-2-icon
                  v-if="showEditButton"
                  @click="goToEdit(key)"
                  class="ml-auto block"
                ></edit-2-icon>
              </div>
              <div class="col-span-12 md:col-span-5 lg:col-span-4">
                <vs-card class="bankCardSettlement shadow-none mb-0">
                  <div slot="header">
                    <div class="items-center">
                      <h5 class="font-normal text-lg md:text-xl text-body-gray">
                        Settlement account
                      </h5>
                    </div>
                  </div>
                  <div class="onboarding-form-settlement">
                    <div class="input-grp w-full mb-6 md:mb-10 mr-0">
                      <div class="">
                        <div class="text-base w-full p-0 mb-2 font-light">Account name</div>
                        <p class="text-dark-blue font-normal">{{ bank.accountName }}</p>
                      </div>
                    </div>

                    <div class="mb-6 md:mb-10">
                      <div class="grid grid-cols-7 gap-y-6 md:gap-y-0 sm:gap-x-8">
                        <div class="col-span-7 sm:col-span-3">
                          <div class="text-base w-full p-0 mb-2 font-light"> BSB </div>
                          <p class="text-dark-blue font-light"> {{ bank.bsb }} </p>
                        </div>

                        <div class="col-span-7 sm:col-span-4">
                          <div class="text-base w-full p-0 mb-2 font-light">Account number</div>
                          <p class="text-dark-blue font-light">{{ bank.accountNumber }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="input-grp w-full mr-0 mb-6 md:mb-6 block">
                      <div class="text-base pl-0  w-full mb-2 font-light">Bank statement</div>
                      <S3FileGenerator :document="bank.bankStatement"/>
                    </div>
                    <div class="w-full mr-0 mb-6 md:mb-6">
                      <div class="text-base pl-0 mb-2 w-full font-light">Which entity owns this bank account?</div>
                      <p class="text-dark-blue font-light">{{ getEntity(bank.entity) }}</p>
                    </div>
                  </div>
                </vs-card>
              </div>
              <div class="col-span-12 md:col-span-6 lg:col-span-6">
                <vs-card class="bankCardBilling shadow-none mb-0">
                  <div slot="header">
                    <div class="flex row justify-between">
                      <h5 class="font-normal text-lg md:text-xl text-body-gray">
                        Billing account
                      </h5>
                    </div>
                  </div>
                  <div class="onboarding-form-billing">
                    <div class="grid lg:grid-cols-12">
                      <div class="col-span-12 md:col-span-7">
                        <div class="input-grp w-full mb-6 md:mb-10 mr-0">
                          <div class="w-full">
                            <div class="text-base w-full p-0 mb-2 font-light">Account name</div>
                            <p class="text-dark-blue font-normal">{{ bank.accountNameForBilling }}</p>
                          </div>
                        </div>
                        <div class="mb-6 md:mb-10">
                          <div class="grid grid-cols-7 gap-y-6 md:gap-y-0 sm:gap-x-8">
                            <div class="col-span-7 sm:col-span-3"
                                 :class="bank.sameAsSettlementAccount ? 'readonly' : 'normal'">
                              <div class="text-base w-full p-0 mb-2 font-light"> BSB</div>
                              <p class="text-dark-blue font-light">{{ bank.bsbForBilling }}</p>
                            </div>
                            <div class="col-span-7 sm:col-span-4">
                              <div class="text-base w-full p-0 mb-2 font-light">Account number</div>
                              <p class="text-dark-blue font-light">{{ bank.accountNumberForBilling }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="input-grp block w-full mb-6 md:mb-6 mr-0">
                          <div class="text-base pl-0 mb-2 w-full font-light">Bank statement</div>
                          <S3FileGenerator :document="bank.bankStatementForBilling"/>
                        </div>
                      </div>
                      <div class="col-span-12">
                        <div class="w-full mr-0 mb-6 md:mb-6">
                          <div class="text-base pl-0 mb-2 w-full font-light">Which entity owns this bank account?</div>
                          <p class="text-dark-blue font-light">{{ getEntity(bank.entityForBilling) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-card>
              </div>
            </div>
          </div>
        </div>
      </vs-col>
      </vs-row>
    </template>
  </div>
</template>

<script>
import { Edit2Icon } from "vue-feather-icons";
import S3FileGenerator from "../../components/S3FileGenerator";
export default {
  components: {
    Edit2Icon,
    S3FileGenerator
  },
  // name:"BankAccount",
  props: ["bankAccount", "userId", "showEditButton", "entityList"],
  methods: {
    goToEdit(key) {
      this.$router.push({
        name: "onboarding-form",
        params: {
          introducerRequestedId: this.userId,
          card: "bankaccount",
        },
        query: {
          scrollTo:"bankAcc"+key
        }
      });
    },
    getEntity(id){
      if(!id) return "";
      let name = "";
      let objIndex = this.entityList.findIndex(
        (obj) => obj.entityId == id
      );
      if(objIndex >= 0){
        name = this.entityList[objIndex].entityType == 'Company' ? this.entityList[objIndex].companyName : this.entityList[objIndex].fullName;
      }
      return name;
    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
};
</script>
